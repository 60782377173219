import React, { useEffect, useState, useContext} from "react";
import Footer from "./Footer";
import Menu from "./Menu";
import TGCBurn from "./TGCBurn";
import XENBurn from "./XENBurn";
import TGXENToken from '../get-abi/TGXEN.json'
import TGXENLPToken from '../get-abi/TGXENLP.json'
import Web3 from "web3";
import swal from "sweetalert";
import Context from "../context/context";
import { Table } from "react-bootstrap";
import { providerURL, txAfterDone, txDone, txStart } from './utils';
import TransactionStatus from "./trxStatus";
import { SwapWidget, darkTheme } from '@uniswap/widgets';

const TGXEN = () => {

const {account, onConnect, providers, onDisconnect} = useContext(Context);
const [isOpen, setIsOpen] = useState(false)
const [isOpenTgx, setIsOpenTgx] = useState(false)
const [claimList, setClaimList] = useState([])
const [canBurnXEN, setCanBurnXEN] = useState(false)
const [refresh, setRefresh] = useState(false)
const [onOpenConnect, setOnOpenConnect] = useState(false)
const [loading, setLoading] = useState(true)
const [TGXENPrice, setTGXENPrice] = useState(0);
const [xenPrice, setXenPrice] = useState(0);
const [totalXenBurned, setTotalXenBurned] = useState(0);
const [circulatingSupply, setCirculatingSupply] = useState(0);
const [availableBalanceToBurn, setAvailableBalanceToBurn] = useState(0);
const [calcDay, setCalcDay] = useState(0);
const [availableTgxenAllocation, setAvailableTgxenAllocation] = useState(0);
const [timeDelayedAllocation, setTimeDelayedAllocation] = useState(0);
const [totalAllocation, setTotalAllocation] = useState(0);
const [availableTgxenRewards, setAvailableTgxenRewards] = useState(0);
const [timeDelayedReward, setTimeDelayedReward] = useState(0);
const [totalRewards, setTotalRewards] = useState(0);
const [myProvider, setMyProvider] = useState(providerURL());
const [showNetworkError, setShowNetworkError] = useState(false);

const onOpen = (type) => {
  setIsOpen(true)
};
const onClose = (e) => {
  setIsOpen(false)
};

const onOpenTgx = (type) => {
  setIsOpenTgx(true)
};
const onCloseTgx = (e) => {
  setIsOpenTgx(false)
};

useEffect(() => {
    init()
    if (account) {
       getContractData()
    }
}, [refresh, account])

const init = async () => {
    const web3 = new Web3(providerURL());
    const TGXENContract = new web3.eth.Contract(TGXENToken.ABI, TGXENToken.Contract);

    let currentTimeStamp = Math.floor(Date.now() / 1000)
    let launchTime = await TGXENContract.methods.LAUNCH_TIME().call()
    let claimableTime = parseInt(launchTime) + (43200)
    
    if(currentTimeStamp >= launchTime)
    {
       setCalcDay(parseInt(await TGXENContract.methods._clcDay().call()));
       setCanBurnXEN(true)
    }
    setTotalXenBurned(parseFloat(parseInt(await TGXENContract.methods.totalXENBurned().call()) / 10 ** 18));
    let totalSupply = parseFloat(parseInt(await TGXENContract.methods.totalSupply().call()) / 10 ** 18);
    let contractBalance = parseFloat(parseInt(await TGXENContract.methods.balanceOf(TGXENToken.Contract).call()) / 10 ** 18);
    let circulatingSupplyHere = totalSupply - contractBalance
    setCirculatingSupply(circulatingSupplyHere)

    let totalTGXenUnclaimedHere = parseFloat(parseInt(await TGXENContract.methods.totalTGXenUnclaimed().call()) / 10 ** 18);
    setAvailableBalanceToBurn(contractBalance - totalTGXenUnclaimedHere)
    fetch("https://api.coingecko.com/api/v3/coins/markets?ids=xen-crypto&vs_currency=usd").then((res) => res.json()).then((json) => {
        setXenPrice(json[0].current_price)
    });

    const TGXENLPContract = new web3.eth.Contract(TGXENLPToken.ABI, TGXENLPToken.Contract);
    TGXENLPContract.methods.getReserves().call().then(async (reserves) => {
        const price = (reserves._reserve1 / 10 ** 18) / (reserves._reserve0 / 10 ** 18);
        try {
            const response = await fetch("https://api.coingecko.com/api/v3/coins/markets?ids=ethereum&vs_currency=usd");
            const jsonData = await response.json();
            setTGXENPrice(price * (jsonData[0].current_price))
        } catch (e) {
            setTGXENPrice(price)
        }
    })
    .catch(error => {
        setTGXENPrice(0)
    });
}

const getContractData = async () => {
    setLoading(true)
    const web3 = new Web3(providers);
    const TGXENContract = new web3.eth.Contract(TGXENToken.ABI, TGXENToken.Contract);

    let totalReceived = parseFloat(parseInt(await TGXENContract.methods.totalReceived(account).call()) / 10 ** 18);
    let totalSend = parseInt(await TGXENContract.methods.totalSend(account).call()) / 10 ** 18;
    let balanceOf = parseInt(await TGXENContract.methods.balanceOf(account).call()) / 10 ** 18;
    var earnedFromHoldingHere = 0
    if((totalReceived + balanceOf) > totalSend) 
    {
        earnedFromHoldingHere = (totalSend + balanceOf) - totalReceived
    }
    setAvailableTgxenRewards(earnedFromHoldingHere)
    let availableReward = parseFloat(parseInt(await TGXENContract.methods.totalTGXenUnclaimed().call()) / 10 ** 18) / 2;
    var timeDelayedRewardHere = 0
    if (circulatingSupply > 0) 
    {
        let userShareInPercent = (balanceOf / circulatingSupply);
        timeDelayedRewardHere = availableReward * userShareInPercent
        setTimeDelayedReward(timeDelayedRewardHere)
    } 
    else 
    {
        setTimeDelayedReward(0)
    }
    setTotalRewards(timeDelayedRewardHere + earnedFromHoldingHere)
    let claimCount = parseInt(await TGXENContract.methods.claimCount(account).call());
    console.log("claimCount=>", claimCount);

    if (claimCount > 0) {
        var cliamDataArr = []
        var canClaim = false;
        var availableTgxenAllocation = 0
        var timeDelayedAllocation = 0
        var totalAllocation = 0

        for (let i = 0; i < claimCount; i++) 
        {
            let claimDay = parseInt(await TGXENContract.methods.claimDays(account, i).call());
            let mapClaimData = await TGXENContract.methods.mapTGXenClaim(account, claimDay).call();
            var claimableAmount = parseInt(mapClaimData.amount) / 10 ** 18;
            if (claimDay <= calcDay) 
            {
                availableTgxenAllocation = availableTgxenAllocation + claimableAmount;
                canClaim = true;
            } 
            else 
            {
                timeDelayedAllocation = timeDelayedAllocation + claimableAmount;
            }
            totalAllocation = totalAllocation + claimableAmount;
            cliamDataArr.push({
                amount: claimableAmount,
                hasCollected: mapClaimData.hasCollected,
                day: claimDay,
                canClaim: canClaim
            })
        }
        setAvailableTgxenAllocation(availableTgxenAllocation + balanceOf);
        setTimeDelayedAllocation(timeDelayedAllocation);
        setTotalAllocation(totalAllocation + balanceOf);
        setClaimList(cliamDataArr);
    } 
    else 
    {
        setAvailableTgxenAllocation(balanceOf)
        setTotalAllocation(balanceOf)
    }
    setLoading(false)
}

const claim = async (targetDay) => {
    const web3 = new Web3(providers);
    const TGXENContract = new web3.eth.Contract(TGXENToken.ABI, TGXENToken.Contract);

    await TGXENContract.methods.claim(targetDay).estimateGas({
        from: account
    }).then(async function(gasAmount) {
        await TGXENContract.methods.claim(targetDay).send({
            from: account
        }).once('sent', function(payload) {
                txStart();
            }).on('error', function(error) {
                swal({
                    title: "Error Found",
                    text: error.message,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Close",
                    closeOnConfirm: false
                });
            }).then(function(receipt) {
               txDone()
               setTimeout(() => {
                  txAfterDone()
               }, 2000)
               setRefresh(!refresh)
            });
        }).catch(function (error) {
            swal({
                title: "Error Found",
                text: error.message,
                icon: "error",
                showCancelButton: false,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Close",
                closeOnConfirm: false
            });
        });
  }

  useEffect(() => {
    setInterval(() => {
      if (window.ethereum != undefined && window.ethereum.networkVersion != 1) {
        setShowNetworkError(true)
      } else {
        setShowNetworkError(false)
      }
    }, 2000)
  }, [account])

  const jsonRpcUrlMap = {
    1: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
  }
  const myDarkTheme = {
     ...darkTheme,
     accent: '#e6a807',
     primary: '#FFFFFF',
     secondary: '#e6a807',
  }
  const TOKEN_LIST = [
      {
      "name": "TGXen",
      "address": "0x51B07E5a0feC6B3DC0C0Df9BC4502c602fc1BF83",
      "symbol": "TGXen",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://tgc.netlify.app/pageimages/tgx-logo.png"
    },
      {
      "name": "XEN Crypto",
      "address": "0x06450dEe7FD2Fb8E39061434BAbCFC05599a6Fb8",
      "symbol": "XEN",
      "decimals": 18,
      "chainId": 1,
      "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/22118.png"
    }
  ]
  return (
    <>
      <section className="font-tgx">
        <Menu onOpenConnect={onOpenConnect} setOnOpenConnect={setOnOpenConnect} showConnect={true} TGXENPrice={TGXENPrice} />
        <section className="sliderSection pageSection">
          <div className="container">
            <div className="slideIn">
              <div className="row align-items-center">
                <div className="col-sm-7">
                  <div className="slideContent">
                    <h1>TGXEN CRYPTO</h1>
                    <ul>
                      <li>First Principles of Crypto</li>
                      <li>
                        Contribution to Xen Burn</li>
                      <li>
                        Eligble for XN Airdrop</li>
                      <li>
                        1:1 TGXen allocation</li>
                      <li>
                        Allocation delays for TGXen price increase</li>
                      <li>
                        Limited Supply</li>
                    </ul>
                    <div className="a_btns">
                      {
                        account ?
                          <>
                            { canBurnXEN ?
                               <a className="cursor-pointer" onClick={onOpenTgx}> Burn Xen Here</a>
                              :
                              <>
                                <a className=""disabled='true' style={{cursor: 'no-drop' }}> Burn Xen Here</a>
                               </>
                            }
                          </>
                          :
                          <>
                            <a className="cursor-pointer" onClick={onConnect}> Connect Wallet</a>
                          </>
                      }
                      <a href="/tgxen-whitepaper.pdf" target="_blank" > Whitepaper</a>
                    </div>
                  </div>
                </div>
               
                <div className="Uniswap col-sm-5 d-flex justify-content-center align-content-center m-auto flex-column align-items-center position-relative">
                    <img src='./pageimages/sliderlogo.png' className="img-responsive rotate-tgx-logo position-absolute" />
                    <div style={{ width: "100%", zIndex: '1' }}>
                       <SwapWidget width="100%" jsonRpcUrlMap={jsonRpcUrlMap} onConnectWalletClick={() => { onConnect() }} tokenList={TOKEN_LIST} defaultInputTokenAddress={TOKEN_LIST[0].address} defaultOutputTokenAddress={TOKEN_LIST[1].address} theme={myDarkTheme}/>
                       <p className="text-center my-0" style={{ color: "#e6a807" }}>NOTE : Adjust Slippage using settings icon</p>
                       <p className="text-center my-0" style={{ color: "#e6a807" }}>NOTE : Use Metamask browser on Mobile</p>
                       {showNetworkError ? <p className="text-center text-danger">SWITCH TO ETHEREUM MAINNET</p>: <></>}
                    </div>
                </div>
              </div>
              <div className="small_boxesa text-center">
                <div className="row mb-5">
                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>TGXen Price</p>
                        <h4>${(TGXENPrice).toFixed(8)}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>XEN Price</p>
                        <h4>${parseFloat(xenPrice).toFixed(8)}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>Total Xen Burned </p>
                        <h4>${(xenPrice * totalXenBurned).toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="snB">
                      <div className="snIN">
                        <p>Circulating supply of TGXen</p>
                        <h4>{(circulatingSupply).toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <div className="snB">
                      <div className="snIN">
                        <p>TGXen left to be allocated for future Burns</p>
                        <h4>{availableBalanceToBurn.toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>Current burn allocation delay</p>
                        <h4>{calcDay * 3} Days</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>Available TGXen Allocation</p>
                        <h4>{availableTgxenAllocation.toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>Time Delayed Allocation</p>
                        <h4>{timeDelayedAllocation.toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>Total Allocation</p>
                        <h4>{totalAllocation.toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>Available TGXen Rewards</p>
                        <h4>{availableTgxenRewards.toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>Time Delayed Rewards</p>
                        <h4>{timeDelayedReward.toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="snB">
                      <div className="snIN">
                        <p>Total Rewards</p>
                        <h4>{totalRewards.toLocaleString()}</h4>
                      </div>
                    </div>
                  </div>

                </div>

                <h4>Claim Your Allocation</h4>
                <Table striped bordered hover variant="dark" className="table-claim">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {!account ?
                        <>
                          <tr>
                            <td></td>
                            <td className="p-1 text-danger">Wallet Not Connected</td>
                            <td></td>
                          </tr></>
                        :
                        <>
                          {loading ?
                            <>
                              <tr key="">
                                <td></td>
                                <td className="p-1">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="36px" height="36px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style={{
                                    margin: 'auto', display: 'block', shapeRendering: "auto"
                                  }}><circle cx="50" cy="50" r="32" strokeWidth="8" stroke="#fff" strokeDasharray="50.26548245743669 50.26548245743669" fill="none" strokeLinecap="round"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform></circle></svg>
                                </td>
                                <td></td>
                              </tr>
                            </>
                            :
                            <>
                              {claimList.length > 0 ?
                                <>
                                  {claimList.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{parseFloat(item.amount).toLocaleString()} TGXen</td>
                                        <td>
                                          {
                                            item.hasCollected ?
                                              <button className="btn btn-claim text-white" disabled>Claimed</button>
                                              :
                                              <>
                                                {item.day < calcDay 
                                                  ?
                                                    < button className="btn btn-claim text-white" onClick={() => { claim(item.day) }}>Claim</button>
                                                  :
                                                     <button className="btn btn-claim text-white" disabled>Claim after {item.day + 1 - calcDay} days</button>
                                                }
                                              </>
                                          }
                                        </td>
                                      </tr>
                                    )
                                  })}
                                </>
                                :

                                <tr>
                                  <td></td>
                                  <td className="p-1 text-danger">No Claimable TGXen</td>
                                  <td></td>
                                </tr>
                              }
                            </>
                          }
                        </>
                      }
                    </>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </section >
        <TransactionStatus />
        <TGCBurn isOpen={isOpen} closeModal={onClose} setRefresh={setRefresh} refresh={refresh} />
        <XENBurn isOpen={isOpenTgx} closeModal={onCloseTgx} setRefresh={setRefresh} refresh={refresh} />
        <Footer />
      </section >
    </>
  );
};
export default TGXEN;