import React, { useState, useEffect, useContext } from 'react';
import { VscChromeClose } from 'react-icons/vsc'
import Modal from 'react-modal';
import swal from 'sweetalert';
import XENContract from '../get-abi/XEN.json'
import TGXENContract from '../get-abi/TGXEN.json'
import { ethers } from 'ethers';
import Web3 from 'web3';
import { providerURL, txAfterDone, txDone, txStart } from './utils';
import Context from "../context/context";

export default function XENBurn({ isOpen, closeModal, setRefresh, refresh }) {
    const {account, providers} = useContext(Context);
    const [burnAmount, setBurnAmount] = useState(0);
    const [balance, setBalance] = useState(0)
    const [allowance, setAllowance] = useState(0)

    useEffect(() => {
        if((parseFloat(burnAmount) >= parseFloat(balance)) && (parseFloat(burnAmount) > 0)) 
        {
           setBurnAmount(parseFloat(balance).toFixed(3));
        }
    }, [burnAmount, balance])

    useEffect(() => {
        if(account) 
        { 
           afterOpenModal() 
        }
    }, [refresh, account])

    const afterOpenModal = async () => {
        const web3 = new Web3(providerURL());
        const contract_token = new web3.eth.Contract(XENContract.ABI, XENContract.Contract);

        let allowance = parseFloat(parseInt(await contract_token.methods.allowance(account, TGXENContract.Contract).call()) / 10 ** 18).toFixed(3);
        setAllowance(allowance);
        let balance = parseFloat(parseInt(await contract_token.methods.balanceOf(account).call()) / 10 ** 18).toFixed(3);
        setBalance(balance)
    }

    const approveToken = async () => {
        const web3 = new Web3(providers);
        const token_contract = new web3.eth.Contract(XENContract.ABI, XENContract.Contract);
        await token_contract.methods.approve(TGXENContract.Contract, '115792089237316195423570985008687907853269984665640564039458').estimateGas({
            from: account
        }).then(async function (gasAmount) {
            await token_contract.methods.approve(TGXENContract.Contract, '115792089237316195423570985008687907853269984665640564039458').send({
                from: account
            }).once('sent', function(payload) {
                closeModal();
                txStart();
            }).on('error', function(error) {
                swal({
                    title: "Error Found",
                    text: error.message,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Close",
                    closeOnConfirm: false
                });
            }).then(function(receipt) {
               txDone()
               setTimeout(() => {
                  txAfterDone()
               }, 2000)
               setRefresh(!refresh)
            });
        }).catch(function (error) {
            swal({
                title: "Error Found",
                text: error.message,
                icon: "error",
                showCancelButton: false,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Close",
                closeOnConfirm: false
            });
        });
    }
    
    const burnToken = async () => {
        const web3 = new Web3(providers);
        const contract_burn = new web3.eth.Contract(TGXENContract.ABI, TGXENContract.Contract);
        let finalAmount = ethers.utils.parseUnits(String(burnAmount), 18).toString();
        await contract_burn.methods.burnXEN(finalAmount).estimateGas({
            from: account
        }).then(async function (gasAmount) {
            await contract_burn.methods.burnXEN(finalAmount).send({
                from: account
            }).once('sent', function(payload) {
                closeModal();
                txStart();
            }).on('error', function(error) {
                swal({
                    title: "Error Found",
                    text: error.message,
                    icon: "error",
                    showCancelButton: false,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Close",
                    closeOnConfirm: false
                });
            }).then(function(receipt) {
               txDone()
               setTimeout(() => {
                  txAfterDone()
               }, 2000)
               setRefresh(!refresh)
            });
        }).catch(function (error) {
            swal({
                title: "Error Found",
                text: error.message,
                icon: "error",
                showCancelButton: false,
                confirmButtonClass: "btn-danger",
                confirmButtonText: "Close",
                closeOnConfirm: false
            });
        });
    }

    return (
        <Modal
            isOpen={isOpen}
            onAfterOpen={afterOpenModal}
            ariaHideApp={false}
            onRequestClose={closeModal}
            className="popupMain">
            <div className="popupcenter">
                <div className="popheader">
                    <h3>Burn XEN Token</h3>
                    <div className="close_icon"><VscChromeClose onClick={closeModal} /></div>
                </div>
                <div className="popbody">
                    <div>
                        <div className='d-flex justify-content-between mb-0 text-warning'>Burn :</div>
                        <div className='popup-input-wrapper'>
                            <input type="number" className='w-100 bg-transparent text-white' value={(burnAmount)} onChange={(e) => setBurnAmount(e.target.value)} />
                        </div>
                        <div className='d-flex justify-content-end mb-0 text-white text-right'>Balance : {parseFloat(balance).toLocaleString()} XEN</div>
                        <br />
                        <div className='d-flex w-100'>
                            <button type='button' className='w-25' onClick={() => { setBurnAmount((25 / 100) * balance) }}>25%</button>
                            <button type='button' className='w-25' onClick={() => { setBurnAmount((50 / 100) * balance) }}>50%</button>
                            <button type='button' className='w-25' onClick={() => { setBurnAmount((75 / 100) * balance) }}>75%</button>
                            <button type='button' className='w-25' onClick={() => { setBurnAmount((100 / 100) * balance) }}>100%</button>
                        </div>
                        <div className='popup-button-group d-flex justify-content-between'>
                            {allowance > 1 ?
                                <>
                                    {balance > 0 ?
                                        <button type='button' className='w-100' onClick={burnToken}>Confirm Burn</button>
                                        :
                                        <button type='button' className='w-100' disabled>Burn XEN Token</button>
                                    }
                                </>
                                :
                                <>
                                    <button type='button' className='w-100' onClick={approveToken}>Approve XEN Token to Burn</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}
