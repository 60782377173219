import React, { useEffect, useState, useContext } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BsDiscord, BsTelegram, BsTwitter, BsYoutube } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { Link } from "react-router-dom";
import Context from "../context/context";

const Menu = () => {
  const {account, onConnect, providers, onDisconnect} = useContext(Context);
  const [usdPrice, setUsdPrice] = useState(0);
  return (
    <>
      <div className="video">
      </div>
      {['lg'].map((expand) => (
        <Navbar key={expand} expand={expand} className="">
          <Container>
            <Navbar.Brand href="">
              <img src="./pageimages/tgx-logo.png" width={'100px'} alt="" className="img-responsive" />
              <span className="logo_price">${parseFloat(usdPrice).toFixed(6)}</span>
             </Navbar.Brand>
             <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas id={`offcanvasNavbar-expand-${expand}`} aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}placement="end">
              <Offcanvas.Header closeButton>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link href="https://thegraysecosystem.com/" className='activeLink'>Home</Nav.Link>
                  <Nav.Link href="https://t.me/TGXEN_OFFICIAL" target="_blank" className="nav_social"><BsTelegram/></Nav.Link>
                  <Nav.Link href="https://twitter.com/TGXen" target="_blank" className="nav_social"><BsTwitter/></Nav.Link>
                    {account ?
                      <Button className="navbtn" onClick={onDisconnect}>{account.substring(0, 7)}....{account.substring(35, 42)}</Button>
                        :
                      <Button className="navbtn" onClick={onConnect}>CONNECT WALLET</Button>
                    }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default Menu;