import React from "react";
import useWindowDimensions from '../hooks/windowSize';
import { BsTelegram, BsTwitter, BsYoutube } from 'react-icons/bs';
import { FaDiscord } from 'react-icons/fa';
import { Link } from "react-router-dom";

const Footer = () => {
  const { height, width } = useWindowDimensions();
  return (
    <>
      <footer className="footSection padSection ">
        <div className="b_footer">
          <div className="container">
            <div className="row clearfix align-items-center">
              <div className="col-sm-10 text-left">
                <div className="d-flex align-items-center">
                  <Link to="./">
                  <img src="./pageimages/tgx-logo.png" alt="" className="img-responsive f_logo" />
                  </Link>
                  <div className="text-warning d-none ms-3 d-sm-block">
                    <a className="text-warning d-block mt-1" target="_blank" href="https://etherscan.io/address/0x51B07E5a0feC6B3DC0C0Df9BC4502c602fc1BF83#code">Contract : 0x51B07E5a0feC6B3DC0C0Df9BC4502c602fc1BF83</a>
                    <a className="text-warning d-block mt-1" target="_blank" href="https://t.me/TGXEN_OFFICIAL"> Telegram : https://t.me/TGXEN_OFFICIAL </a>
                    <a className="text-warning d-block mt-1" target="_blank" href="https://twitter.com/TGXen">Twitter : https://twitter.com/TGXen</a>
                  </div>
                  <div className="text-warning d-block ms-3 d-sm-none">
                    <a className="text-warning m-1" target="_blank" href="https://etherscan.io/address/0x51B07E5a0feC6B3DC0C0Df9BC4502c602fc1BF83#code">Smart Contract</a>
                    <a className="text-warning m-1" target="_blank" href="https://t.me/TGXEN_OFFICIAL">Telegram </a>
                    <a className="text-warning m-1" target="_blank" href="https://twitter.com/TGXen">Twitter</a>
                  </div>
                </div>
              </div>
              <div className="col-sm-2 text-right">
                <div className="social_icon">
                  <a target="_blank" href=""><BsYoutube/></a>
                  <a target="_blank" href=""><FaDiscord/></a>
                  <a target="_blank" href="https://t.me/TGXEN_OFFICIAL"><BsTelegram/></a>
                  <a target="_blank" href="https://twitter.com/TGXen"><BsTwitter/></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copy">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 text-left">
                <p>© Copyright 2023 All Rights Reserved</p>
              </div>
              <div className="col-sm-6 text-right">
                <p>
                  <a href="#">Disclaimer</a>
                  <a href="#">Privacy</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;