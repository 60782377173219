import $ from 'jquery'

export const providerURL = () => {
  const SCAN_LINK = {
     testnet: 'https://mainnet.infura.io/v3/5f23135563574f68a2098b6c5f56ebd0',
     mainnet: 'https://mainnet.infura.io/v3/5f23135563574f68a2098b6c5f56ebd0'
  }
  return SCAN_LINK.mainnet
}

export function txStart() {
  $("#check").removeClass('ready')
  $("#check").addClass('progressLoading')
  $("#pendingTrxPopUp").show()
}
export function txDone() {
  $("#txStatusHeading").html("Transaction Completed")
  $("#check").removeClass('progressLoading')
  $("#check").addClass('ready')
}
export function txAfterDone() {
  $("#txStatusHeading").html("Transaction in Progress")
  $("#check").addClass('progressLoading')
  $("#check").removeClass('ready')
  $("#pendingTrxPopUp").hide()
}