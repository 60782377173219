import React, { useState } from 'react';
import swal from "sweetalert"
import Web3 from "web3";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import TGXEN from './components/TGXEN';
import Context from './context/context';
import { EthereumProvider } from "@walletconnect/ethereum-provider";
import Reactconnectwallet from './react-wallet/reactconnectwallet'
import './style-sheet/app.css';
import './style-sheet/responsive.css';

function App() {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [providers, setProviders] = useState();
  const [account, setAccount] = useState();
  const projectId = 'a5fdc7a6cf0cd700ec545c47284cde11';
  let provider;

  async function reactconnectwalletModal() {
     setIsOpen(true);
  }

  async function onMetamaskConnect(){
     await setIsOpen(false);
      try {
          await window.ethereum.request({method:'eth_requestAccounts'});
          provider =  window.ethereum;
          await setProviders(provider);  
          localStorage.setItem("lastprovider", 'metamask');
          await window.ethereum.enable();
          provider.on("accountsChanged", (account) => {
             setAccount(account[0]);
          });
          provider.on("chainChanged", (chainId) => {
             if(parseInt(chainId) !='1')
             {
                 swal({
                    title: "Error Found",
                     text: 'Make Sure You Are Using The ETH Network',
                    type: "error",
                    showCancelButton: false,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Ok",
                    closeOnConfirm: false
                });
             }
          });
         await init();
      } catch (e) {
          console.log("Could not get a metamask connection", e);
      }
  }
  
  async function onWalletConnect() {
     await setIsOpen(false);
     try {
          provider = await EthereumProvider.init({
              projectId,
              showQrModal: true,
              qrModalOptions: {
                themeMode: "light",
              },
              chains: [1]
          });
          await setProviders(provider); 
          localStorage.setItem("lastprovider", 'walletconnect'); 
          await provider.enable();
          provider.on("accountsChanged", (account) => {
             setAccount(account[0]);
          });
          provider.on("chainChanged", (chainId) => {
             if(parseInt(chainId) !='1')
             {
                 swal({
                    title: "Error Found",
                    text: 'Make Sure You Are Using The ETH Network',
                    type: "error",
                    showCancelButton: false,
                    confirmButtonClass: "btn-danger",
                    confirmButtonText: "Ok",
                    closeOnConfirm: false
                });
             }
          });
          await init();
      } catch (e) {
          console.log("Could not get a wallet connection", e);
          return;
      }
  }

  async function onConnect() {
     setIsOpen(true);
  }

  async function onDisconnect() {
     setAccount();
     localStorage.setItem("lastprovider", ''); 
  }

  async function init() {
     let web3 = new Web3(provider);
     let account = await web3.eth.getAccounts();
     setAccount(account[0]);
     let chainId = await web3.eth.getChainId();
     if(parseInt(chainId) !='1')
     {
         swal({
            title: "Error Found",
            text: 'Make Sure You Are Using The ETH Network',
            type: "error",
            showCancelButton: false,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Ok",
            closeOnConfirm: false
        });
     }
  }
  return (
    <>
        <Context.Provider value={{ account, onConnect, providers, onDisconnect}}>
          <Router>
            <Routes>
               <Route path='*' element={<TGXEN />} />
             </Routes>
          </Router>
        </Context.Provider>
        <Reactconnectwallet onWalletConnect={onWalletConnect} onMetamaskConnect={onMetamaskConnect} modalIsOpen={modalIsOpen} setIsOpen={setIsOpen}  />
    </>
  );
}
export default App;
